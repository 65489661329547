import style from './style.module.scss'
import React, { useState } from 'react'

export const Modal = () => {
    const [modal, setModal] = useState({
        modal: true,
    })

    const handleChangeModal = () => {
        setModal({
            modal: false
        })
    }


    const ModalIndex = (
        <>

            <div className={style.modalOverlay}>
                <div className={style.modal}>
                    <div className={style.form}>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", textAlign: "initial", marginBottom: 25 }}>
                                <span style={{ fontSize: "1.5rem" }}>Para realizar o seu agendamento online:</span><br />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", textAlign: "initial", marginBottom: 15 }}>
                                <span style={{ fontSize: "1.2rem", }}>- Esteja com o seu pedido médico.<br />
                                    - Vai fazer pelo convênio? Tenha em mãos o número da sua carteirinha e se for cliente <span style={{ color: "#02884d" }}>Unimed</span> nos envie o número de autorização.<br />
                                    - Caso for realizar o agendamento particular de seu teste de covid19, você poderá realizar o pagamento online em até 3x no cartão de crédito.
                                </span>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", textAlign: "start", marginBottom: 10 }}>
                                <p>Termo de Aceite: Ao clicar em confirmar, você aceita ser contactado pelos nossos atendentes
                                    via whatsapp, conforme nosso, <a href="https://saopaulopatologia.com.br/politica-de-privacidade">termo de privacidade</a>.
                                </p>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", textAlign: "start", marginBottom: 25 }}>
                                {/* <span style={{ fontSize: 11 }}>
                                    Em qualquer momento você poderá nos contatar pela nossa central, através do telefone ou
                                     <a href="https://api.whatsapp.com/send?phone=553121260100"> whatsapp</a> – (31) 2126-0100.
                                </span> */}
                            </div>
                            <button onClick={handleChangeModal}>Confirmar</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

    return (
        <>
            {modal.modal && ModalIndex}
        </>
    )
}

